"use strict";
exports.__esModule = true;
exports.initUpgradeNotification = void 0;
var version_1 = require("../../utils/version");
var initUpgradeNotification = function () {
    var container = document.querySelector('[data-upgrade]');
    if (!container) {
        return;
    }
    /*
    *   Expected JSON payload:
    *   {
    *       "version" : "1.2.3",    // Version number. Can only contain numbers and decimal point.
    *       "url" : "https://wagtail.org"  // Absolute URL to page/file containing release notes or actual package. It's up to you.
    *   }
    */
    var releasesUrl = 'https://releases.wagtail.org/latest.txt';
    var currentVersion = container.dataset.wagtailVersion;
    fetch(releasesUrl).then(function (response) {
        if (response.status !== 200) {
            // eslint-disable-next-line no-console
            console.log("Unexpected response from " + releasesUrl + ". Status: " + response.status);
            return false;
        }
        return response.json();
    }).then(function (data) {
        if (data && data.version && version_1.versionOutOfDate(data.version, currentVersion)) {
            container.querySelector('[data-upgrade-version]').innerText = data.version;
            container.querySelector('[data-upgrade-link]').setAttribute('href', data.url);
            container.style.display = '';
        }
    })["catch"](function (err) {
        // eslint-disable-next-line no-console
        console.log("Error fetching " + releasesUrl + ". Error: " + err);
    });
};
exports.initUpgradeNotification = initUpgradeNotification;
